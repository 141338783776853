//
// idle Timer for JPM
// logout timeout 30 minutes
// warning message 29 minutes
//

// We will store the "lastIdleTimestamp" in sessionStorage
// for counter sync between Angular and React app

// CONSTANTS
// var warningTimeOutInMiliseconds = 1000 * 60 * 29 // idle 29 minutes for warning message
var warningTimeOutInMiliseconds = 1000 * 5
var throttleInMiliseconds = 1000 * 30 // 30 seconds throttle heartbeat API calling
var progressBarTimeoutInMiliseconds = 1000 * 60 // logoutTimeoutInMiliseconds - warningTimeOutInMiliseconds
var logoutTimeoutInMiliseconds =
  warningTimeOutInMiliseconds + progressBarTimeoutInMiliseconds // idle 30 minutes for logout

var HEARTBEAT_URL = '/mock-api/heartbeat.json' // Hearbeat API URL
var LOGOUT_URL = '/eTrading/login?timeout-logged-out' // logout redirect URL

// USER Actions : mousemove || mousedown || keypress || touchmove
var USER_ACTIONS = ['mousedown', 'keypress']

// Translations
var EN = {
  headerMessage: 'You have been inactive for a while',
  warningMessage:
    'I have been inactive and will be logged out in next :warningTimeOut',
  systemMessage: 'Logging out in :countdown',
  stayLogin: 'STAY LOGGED IN',
  logoutNow: 'LOG OUT NOW',
  logout: 'Logging out',
  loggedOut: 'You have been logged out due to inactivity',
  ok: 'OK',
  seconds: 's',
  minutes: 'm',
  hours: 'h',
}

var ZH_HK = {
  headerMessage: '您已閒置一段時間',
  warningMessage: '將於:warningTimeOut後自動登出',
  systemMessage: '將於:countdown後自動登出',
  stayLogin: '保持登入狀態',
  logoutNow: '立即登出',
  logout: '正在登出',
  loggedOut: '由於超時閒置，您已被自動登出',
  ok: '確定',
  seconds: '秒',
  minutes: '分',
  hours: '時',
}

var ZH_TW = {
  headerMessage: '您已閒置一段時間',
  warningMessage: '將於:warningTimeOut後自動登出',
  systemMessage: '將於:countdown後自動登出',
  stayLogin: '保持登入狀態',
  logoutNow: '立即登出',
  logout: '正在登出',
  loggedOut: '由於超時閒置，您已被自動登出',
  ok: '確定',
  seconds: '秒',
  minutes: '分',
  hours: '時',
}

// key variables
var IDLE_TIMESTAMP_SESSION = 'lastIdleTimestamp' // Session storage variable stored login timestamp
var TRANSLATION_KEY = 'i18nextLng'
var AUTH_TOKEN = 'etrading.AUTH_CHECKED'
var session = window.sessionStorage.getItem(IDLE_TIMESTAMP_SESSION)

// idle Timer + warningTimer
var timeoutId
var warningTimeOutId
var countdownId
var throttlePause

// helper
function createDiv(className) {
  var div = document.createElement('div')
  div.className = className
  return div
}

function _qs(selector) {
  return document.querySelector(selector)
}

function updateMessage(id, message) {
  document.getElementById(id).innerText = message
}

function formatMilisecondsToRemaingTime(miliseconds, translations) {
  var totalSeconds = miliseconds / 1000
  var hours = Math.floor(totalSeconds / 3600)
  totalSeconds %= 3600
  var minutes = Math.floor(totalSeconds / 60)
  var seconds = totalSeconds % 60
  var formatText = ''

  if (!!hours) {
    formatText = formatText + hours + translations.hours
  }
  if (!!minutes) {
    formatText = formatText + minutes + translations.minutes
  }
  if (!!seconds) {
    formatText = formatText + seconds + translations.seconds
  }

  return formatText
}

function milisecondsToMinutes(miliseconds) {
  return miliseconds / 1000 / 60
}

function milisecondsToSeconds(miliseconds) {
  return miliseconds / 1000
}

function addMultipleEventListener(element, events, handler) {
  events.forEach(function (e) {
    element.addEventListener(e, handler)
  })
}

function throttle(func, time) {
  if (throttlePause) return
  throttlePause = true
  setTimeout(function () {
    func && func()
    throttlePause = false
    console.log('send heartbeat API')
  }, time)
}

// Translations depends on Translation key: i18nextLng
function translations() {
  var lang = window.localStorage.getItem(TRANSLATION_KEY)
  var translation

  translation = EN

  if (lang === 'zh-HK') {
    translation = ZH_HK
  }

  if (lang === 'zh-TW') {
    translation = ZH_TW
  }

  return translation
}

// Logout function
function logout() {
  clearTimer()
  // Cleanup storage when logout
  window.sessionStorage.removeItem(IDLE_TIMESTAMP_SESSION)
  window.sessionStorage.removeItem(AUTH_TOKEN)

  $('#idleModal').modal('hide')

  // Redirect page (Can change)
  window.location.assign(LOGOUT_URL)
}

function countdownTimer(timeout, interval) {
  var count = 1
  var max = timeout
  var countdown = timeout
  var systemMessage = translations().systemMessage
  var hours = translations().hours
  var minutes = translations().minutes
  var seconds = translations().seconds

  countdownId = setInterval(function () {
    countdown -= 1000
    count++

    // progress bar width increase align with countdown timer
    _qs('.idle-progress-overlay').style.width =
      (100 / (max / 1000)) * count + '%'
    _qs('.idle-progress-overlay').style.transition = 'width 1s linear'

    updateMessage(
      'system_message',
      systemMessage.replace(
        ':countdown',
        formatMilisecondsToRemaingTime(countdown, {
          hours,
          minutes,
          seconds,
        })
      )
    )
    if (countdown === 0) {
      updateMessage('system_message', translations().logout)
      clearTimeout(countdownId)
    }
  }, interval)
}

// Warning function after warning timeout passed
function showWarningModal() {
  createIdleModal()

  $('#idleModal').on('hidden.bs.modal', function () {
    resetProgressBar()
    resetTimer()
  })

  $('#idleModal').modal({ backdrop: 'static', keyboard: false })
  countdownTimer(progressBarTimeoutInMiliseconds, 1000)
}

// Case 1: No session for initial login, created session time
// Case 2: Session exist and within timeout, update session time
// Case 3: Session exist and exceed timeout, logout
function sessionHandling() {
  var sessionTime = Date.now()

  if (
    !session ||
    parseInt(session, 10) - sessionTime < logoutTimeoutInMiliseconds
  ) {
    window.sessionStorage.setItem(IDLE_TIMESTAMP_SESSION, sessionTime)
    return
  }

  logout()
}

// Create Timer for first login
function startTimer() {
  timeoutId = window.setTimeout(logout, logoutTimeoutInMiliseconds)
  warningTimeOutId = window.setTimeout(
    showWarningModal,
    warningTimeOutInMiliseconds
  )
  sessionHandling()
}

function clearTimer() {
  window.clearTimeout(timeoutId)
  window.clearTimeout(warningTimeOutId)
  window.clearTimeout(countdownId)
}

// Captured defined user actions (mouseclick or API request) , reset timer
// Update session
function resetTimer(e) {
  if (isLoggedIn()) {
    if (!$('#idleModal').is(':visible')) {
      clearTimer()
      startTimer()
      sessionHandling()
      console.log('reset Timer')
    }
  }
}

// throttle request to Heartbeat API
function refreshHeartBeat() {
  if (isLoggedIn()) {
    throttle(function () {
      fetch(HEARTBEAT_URL)
    }, throttleInMiliseconds)
  }
}

// add eventlistener to capture user actions
function setupTimers() {
  addMultipleEventListener(document, USER_ACTIONS, resetTimer)
  addMultipleEventListener(document, USER_ACTIONS, refreshHeartBeat)

  startTimer()
}

// intercept xmlHttpRequest (reset timer + send request to Heartbeat API)
function handleHttpRequestOpen() {
  var open = window.XMLHttpRequest.prototype.open

  function openReplacement(method, url) {
    this.addEventListener('load', function () {
      resetTimer()
    })

    this.addEventListener(
      'readystatechange',
      function () {
        if (this.readyState === 4 && this.status === 401) {
          logout()
        }
      },
      false
    )

    // window.XMLHttpRequest send request to heartbeat API
    return open.apply(this, arguments)
  }

  window.XMLHttpRequest.prototype.open = openReplacement
}

function isLoggedIn() {
  return !!window.sessionStorage.getItem(AUTH_TOKEN)
}

function resetProgressBar() {
  _qs('.idle-progress-overlay').style.width = '0px'
  _qs('.idle-progress-overlay').style.transition = ''
  _qs('#system_message').innerText = ''
}

function createIdleModal() {
  if (_qs('#idleModal')) _qs('#idleModal').remove()

  var hours = translations().hours
  var minutes = translations().minutes
  var seconds = translations().seconds
  var header = translations().headerMessage
  var message = translations().warningMessage.replace(
    ':warningTimeOut',
    formatMilisecondsToRemaingTime(progressBarTimeoutInMiliseconds, {
      hours,
      minutes,
      seconds,
    })
  )
  var primaryText = translations().stayLogin
  var secondaryText = translations().logoutNow

  var idleModalDiv = createDiv('modal fade')
  idleModalDiv.id = 'idleModal'
  idleModalDiv.setAttribute('tabindex', '-1')
  idleModalDiv.setAttribute('role', 'dialog')
  idleModalDiv.setAttribute('aria-labelledby', 'logoutModal')
  idleModalDiv.setAttribute('aria-hidden', 'true')

  var modalDialog = createDiv('modal-dialog')
  var modalContent = createDiv('idle-modal-content')
  var modalBody = createDiv('idle-modal-body')
  var modalHeader = createDiv('idle-modal-header')
  var modalText = document.createElement('span')
  modalText.innerText = header
  var modalTextDiv = createDiv('idle-modal-text')
  var modalText1 = document.createElement('span')
  modalText1.id = 'system_message'
  modalText1.innerText = message

  var idleDiv = createDiv('idle-progress-container')
  var idleProgress = createDiv('idle-progress')
  var idleProgressOverlay = createDiv('idle-progress-overlay')
  var primaryBtnDiv = createDiv('btn-primary-div')
  var primaryBtn = document.createElement('button')
  primaryBtn.className = 'primary-theme-btn'
  primaryBtn.setAttribute('data-dismiss', 'modal')
  primaryBtn.innerText = primaryText

  var defaultBtnDiv = createDiv('btn-default-div')
  var defaultBtn = document.createElement('button')
  defaultBtn.setAttribute('class', 'default-btn')
  defaultBtn.id = 'logout'
  defaultBtn.innerText = secondaryText
  defaultBtn.addEventListener('click', () => logout())

  primaryBtnDiv.appendChild(primaryBtn)
  defaultBtnDiv.appendChild(defaultBtn)

  modalHeader.appendChild(modalText)
  modalTextDiv.appendChild(modalText1)
  idleDiv.appendChild(idleProgress)
  idleDiv.appendChild(idleProgressOverlay)

  modalBody.appendChild(modalHeader)
  modalBody.appendChild(modalTextDiv)
  modalBody.appendChild(idleDiv)
  modalBody.appendChild(primaryBtnDiv)
  modalBody.appendChild(defaultBtnDiv)

  modalContent.appendChild(modalBody)
  modalDialog.appendChild(modalContent)
  idleModalDiv.appendChild(modalDialog)

  _qs('body').appendChild(idleModalDiv)
}

function createLogoutModal() {
  if (_qs('#logoutModal')) _qs('#logoutModal').remove()

  var header = translations().loggedOut
  var btn = translations().ok

  var logoutModalDiv = createDiv('modal fade')
  logoutModalDiv.id = 'logoutModal'
  logoutModalDiv.setAttribute('tabindex', '-1')
  logoutModalDiv.setAttribute('role', 'dialog')
  logoutModalDiv.setAttribute('aria-labelledby', 'logoutModal')
  logoutModalDiv.setAttribute('aria-hidden', 'true')

  var modalDialog = createDiv('modal-dialog')
  var modalContent = createDiv('idle-modal-content')
  var modalBody = createDiv('idle-modal-body')

  var modalHeader = createDiv('logout-modal-header')
  var modalText = document.createElement('span')
  modalText.innerText = header

  var logoutBtnDiv = createDiv('logout-btn-div')
  var logoutBtn = document.createElement('button')
  logoutBtn.className = 'primary-theme-btn'
  logoutBtn.setAttribute('data-dismiss', 'modal')
  logoutBtn.innerText = btn

  logoutBtnDiv.appendChild(logoutBtn)
  modalHeader.appendChild(modalText)
  modalBody.appendChild(modalHeader)
  modalBody.appendChild(logoutBtnDiv)
  modalContent.appendChild(modalBody)
  modalDialog.appendChild(modalContent)
  logoutModalDiv.appendChild(modalDialog)

  _qs('body').appendChild(logoutModalDiv)
}

function initIdleTimer() {
  if (isLoggedIn()) {
    setupTimers()
    handleHttpRequestOpen()
  }

  if (window.location.search.indexOf('timeout-logged-out') !== -1) {
    // render logout Modal
    createLogoutModal()

    $('#logoutModal').modal('toggle')
  }
}

window.onload = initIdleTimer
